import React from 'react';
import { TabComponentProps } from '../../../../../pages/news/context/Interfaces';
import { renderInput } from '../../../../../services/form/FormElements';

const DescriptionTab: React.FC<TabComponentProps> = ({ 
    state, 
    dispatch, 
    handleChange, 
    formConfig,
    removeError,
}) => {
    return (
        <div>
            <div className="row newsDescription">
                {formConfig.map((fields: any, index: number) => (
                    <div key={index} className="d-flex flex-row gap-3 my-3">
                        {Object.values(fields)?.map((field: any) => (                            
                            <div key={field?.name} className="flex-1">
                                {renderInput(
                                    removeError,
                                    field,
                                    (e: any) => handleChange(e, field?.name),
                                    state[state?.currentTab]?.data,
                                    state[state?.currentTab]?.errors
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DescriptionTab;
