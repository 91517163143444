import React, { ChangeEvent, useEffect, useState } from "react";
import TableStructure from "../../../atoms/TableStructure";
import { APICALL } from "../../../../services/ApiServices";
import { CREATE_GROUP, MANAGE_GROUP } from "../../../../routes/ApiEndpoints";
import { manageState, tableHeaders, tableValues } from "./State";
import { useNavigate } from "react-router-dom";
import { t } from "../../../../services/translation/TranslationUtils";
import Pagination from "../../../molecules/Paginations";
import Popup from "../../../molecules/Popup";
import CustomNotify from "../../../atoms/CustomNotify";
import ManageFilters from "./ManageFilters";
import Button from "../../../atoms/Button";
import TitleAtom from "../../../common/TitleAtom";

const ManageGroupOrganism: React.FC = () => {
  const [state, setState] = useState(manageState);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [state?.current_page, state?.refresh]);

  const fetchData = async (search?: boolean) => {
    let postData = {
      limit: state.limit,
      current_page: search ? 1 : state?.current_page,
      initialCall: state?.initialCall,
      manage: state?.manage,
      search: state?.filters
    }
    const response = await APICALL.service(MANAGE_GROUP, 'POST', postData);

    if (response.status === 200) {
      let data: any = {
        data: response?.data?.data,
        totalPages: response?.data?.totalPages,
        offset: response?.data?.offset,
        totalCount: response?.data?.totalCount,
        initialCall: false,
        current_page: search ? 1 : state?.current_page
      }
      if (response?.data?.options) {
        data = {
          ...data,
          options: response?.data?.options
        }
      }
      setState((prevState: any) => ({ ...prevState, ...data }))
    }
  }

  const handleArchivePopUp = (value: any) => {
    setState((prevState: any) => ({
      ...prevState,
      archivepopup: {
        ...prevState.archivepopup,
        id: value?.id,
        showpopup: true,
        status: value?.activity
      }
    }));
  }

  const handleArchive = async () => {
    const postdata = {
      status: state?.archivepopup?.status ? 'false' : 'true',
      group_id: state?.archivepopup?.id,
      change_status: true,
    };

    try {
      const response = await APICALL.service(CREATE_GROUP, "POST", postdata);
      CustomNotify({ type: response.status === 200 ? "success" : "error", message: response.message });
      if (response.status === 200) {
        setState((prevState: any) => ({
          ...prevState,
          archivepopup: {
            ...prevState.archivepopup,
            value: null,
            showpopup: false,
            status: null
          },
          data: state?.data?.map((item: any) => {
            if (item?.id === state?.archivepopup?.id) {
              return {
                ...item,
                table: {
                  ...item.table,
                  activity: !state?.archivepopup?.status
                },
                status: !state?.archivepopup?.status,
              }
            } else {
              return item
            }
          })
        }));
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => {
    setState((prevState: any) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [field]: e
      }
    }));
  }

  const handlePaginationClick = (pageNumber: number) => {
    setState((prevState: any) => ({
      ...prevState,
      current_page: pageNumber
    }));
  };

  const handleClear = () => {
    setState((prevState: any) => ({
      ...prevState,
      filters: manageState?.filters,
      current_page: 1,
      refresh: !state?.refresh
    }));
  }

  return (
    <div>
      <div className="search-bar py-3">
        <div className="row">
          <TitleAtom title={t("Manage group")} />
          <div className="col-6">
            <Button
              className="create_button float-end px-3"
              title={'+ ' + t("Create group")}
              handleClick={() => navigate('/groups/create')}
            />
          </div>
        </div>
        <ManageFilters
          handleChange={handleChange}
          options={state?.options}
          data={state?.filters}
          handleSubmit={() => fetchData(true)}
          handleClear={handleClear}
        />
      </div>

      <TableStructure
        isAction
        data={state.data}
        headers={tableHeaders}
        values={tableValues}
        handleEdit={(value) => navigate(`/groups/create?edit=${value.id}`)}
        handleClone={(value) => navigate(`/groups/create?clone=${value.id}`)}
        handleArchive={handleArchivePopUp}
      />
      {state?.totalPages > 1 &&
        <Pagination
          currentPage={state?.current_page}
          totalPages={state?.totalPages}
          handlePaginationClick={handlePaginationClick}
        />
      }
      {state?.archivepopup?.showpopup &&
        <Popup
          body={t(`Are you sure you want to ${state?.archivepopup?.status ? "deactivate" : "activate"} this group`) + '?'}
          yestext={`${state?.archivepopup?.status ? "Archive" : "Unarchive"}`}
          notext="Cancel"
          submit={handleArchive}
          cancel={() => setState((prevState: any) => ({ ...prevState, archivepopup: { ...prevState?.archivepopup, showpopup: false, id: null, status: null } }))}
        />
      }
    </div>
  );
};

export default ManageGroupOrganism;
