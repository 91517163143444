import React, { ChangeEvent, useEffect, useState } from "react";
import { APICALL } from "../../../services/ApiServices";
import { manageState, tableHeaders, tableValues } from "./State";
import { useNavigate } from "react-router-dom";
import { t } from "../../../services/translation/TranslationUtils";
import ManageFilters from "./ManageFilters";
import TableStructure from "../../atoms/TableStructure";
import Pagination from "../../molecules/Paginations";
import { manageTranslation, saveTranslation } from "../../../routes/ApiEndpoints";
import Popup from "../../molecules/Popup";
import CustomNotify from "../../atoms/CustomNotify";
import TranslationEditPopup from "../../molecules/TranslationEditPopup";
import TitleAtom from "../../common/TitleAtom";


const ManageTranslationsOrganism: React.FC = () => {
  const [state, setState] = useState(manageState);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [state?.current_page]);

  const fetchData = async (search?: boolean) => {
    let postData = {
      limit: state.limit,
      current_page: search ? 1 : state?.current_page,
      initialCall: state?.initialCall,
      manage: state?.manage,
      site_key: state?.filters?.site_key,
      language_id: state?.filters?.language_id,
      search: state?.filters?.search
    }
    const response = await APICALL.service(manageTranslation, 'POST', postData);

    if (response.status === 200) {
      let data: any = {
        data: response?.data?.data,
        totalPages: response?.data?.pagination?.totalPages,
        offset: response?.data?.pagination?.offset,
        totalCount: response?.data?.pagination?.totalCount,
        initialCall: false,
        current_page: search ? 1 : state?.current_page
      }
      if (response?.data?.options) {
        data = {
          ...data,
          options: response?.data?.options
        }
      }
      setState((prevState: any) => ({ ...prevState, ...data }))
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => {
    setState((prevState: any) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [field]: e?.target?.value ?? e
      }
    }));
  }

  const handlePaginationClick = (pageNumber: number) => {
    setState((prevState: any) => ({
      ...prevState,
      current_page: pageNumber
    }));
  };

  const handleEditPopUp = (value: any) => {
    handlePopUpState({ string_id: value?.string_id, showpopup: true, newstring: value?.translated_string })
  }

  const handlePopUpState = (values: any, newstring?: any) => {
    setState((prevState: any) => ({
      ...prevState,
      popup: {
        ...prevState.popup,
        editpopup: {
          ...prevState.popup.editpopup,
          string_id: values?.string_id,
          showpopup: values?.showpopup,
          newstring: values?.newstring
        }
      },
      data: newstring ? state?.data?.map((item: any) => {
        if (item?.string_id == state?.popup?.editpopup?.string_id) {
          return {
            ...item,
            translated_string: newstring,
            table: {
              ...item.table,
              translated_string: newstring
            }
          }
        } else {
          return item
        }
      }) : state?.data
    }));
  }

  const handleSave = async (newstring: string) => {
    const postdata = {
      string: newstring,
      string_id: state?.popup?.editpopup?.string_id,
      target_language_id: state?.filters?.language_id,
    };

    try {
      const response = await APICALL.service(saveTranslation, "POST", postdata);
      CustomNotify({ type: response.status === 200 ? "success" : "error", message: response.message });
      if (response.status === 200) {
        handlePopUpState({ value: null, showpopup: false, status: null }, newstring);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className="search-bar py-3">
        <div className="row mb-2">
          <TitleAtom title={t("Manage translations")} />
        </div>
        <ManageFilters
          handleChange={handleChange}
          options={state?.options}
          data={state?.filters}
          handleSubmit={() => fetchData(true)}
        />
      </div>
      <TableStructure
        isAction
        data={state.data}
        headers={tableHeaders}
        values={tableValues}
        handleEdit={handleEditPopUp}
      />
      {state?.totalPages > 1 &&
        <Pagination
          currentPage={state?.current_page}
          totalPages={state?.totalPages}
          handlePaginationClick={handlePaginationClick}
        />
      }
      {state?.popup?.editpopup?.showpopup &&
        <Popup
          title={t("Edit string")}
          body={<TranslationEditPopup
            oldstring={state?.popup?.editpopup?.newstring}
            handleSave={handleSave}
            handleCancel={() => handlePopUpState({ value: null, showpopup: false, status: null })}
          />}
          close={() => handlePopUpState({ value: null, showpopup: false, status: null })}
        />
      }
    </>
  );
};

export default ManageTranslationsOrganism;
