import React, { ChangeEvent, MouseEvent, MouseEventHandler } from "react";
import ImageWithTitle from "../atoms/ImageWithTitle";

interface ListSelectFieldProps {
  name?: string;
  value?: any;
  checked?: boolean;
  path?: string; // Assuming path is required
  iconLabel?: string; // Assuming iconLabel is required
  disable?: boolean;
  onClick?: MouseEventHandler<HTMLInputElement>;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  id?: string | number;
}

const ListSelectField: React.FC<ListSelectFieldProps> = ({
  name,
  value,
  checked,
  path,
  iconLabel,
  disable = false,
  onClick,
  onChange,
  id,
}) => {
  const handleClick = (event: MouseEvent<HTMLLabelElement>) => {
    // Only trigger the checkbox when clicking on the input element
    if ((event.target as HTMLInputElement).tagName !== "INPUT") {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <div className="d-flex justify-content-between p-2 align-items-center cursor-pointer listselect border-bottom">
      <div style={{ width: "95%" }}>
        <ImageWithTitle
          path={path}
          classname1=""
          classname2=""
          title={iconLabel}
        />
      </div>
      <div style={{ width: "5%" }}>
        <input
          name={name}
          type="checkbox"
          checked={checked || false}
          id={id?.toString()}
          disabled={disable}
          onChange={onChange}
          onClick={onClick}
          className="form-check-input shadow-none rounded-0 me-2"
          style={{ border: "1px solid #4759694D" }}
        />
      </div>
    </div>
  );
};

export default ListSelectField;
