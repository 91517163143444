import React, { useEffect } from 'react';
import { useGroupDispatch, useGroupState } from '../../../pages/groups/context/GroupContext';
import { APICALL } from '../../../services/ApiServices';
import { CREATE_GROUP, MANAGE_GROUP } from '../../../routes/ApiEndpoints';
import './CreateGroupOrganism.css';
import ActionButtonGroup from '../../molecules/ActionButtonGroup';
import { getFormFields } from '../../../pages/groups/context/State';
import DescriptionTab from './tabs/DescriptionTab';
import CommonTab from './tabs/CommonTab';
import { GroupStateTabs, TabComponentProps } from '../../../pages/groups/context/Interfaces';
import { dynamicSubmitAndBackFunction, handleTabChange } from '../../../services/HandleTabServices';
import { SEARCH_OPTIONS, SET_ERROR, SET_OPTIONS, UPDATE_FIELD } from '../../../pages/groups/context/Actions';
import { useNavigate } from 'react-router-dom';
import CustomNotify from '../../atoms/CustomNotify';
import FormValidation from '../../../services/formvalidation/Validation';
import { t } from '../../../services/translation/TranslationUtils';
import TitleAtom from '../../common/TitleAtom';

const tabComponents: {
  [key: string]: React.ComponentType<TabComponentProps>;
} = {
  description: DescriptionTab,
  user: CommonTab,
  team: CommonTab,
  admin: CommonTab,
};

const CreateGroupOrganism: React.FC = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const editId = searchParams.get("edit");
  const cloneId = searchParams.get("clone");

  const navigate = useNavigate();
  const state = useGroupState();
  const dispatch = useGroupDispatch();
  const formConfig: any = getFormFields(state);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const postdata = {
      initialCall: true,
    };
    try {
      let id = editId ?? cloneId;
      const response = await APICALL.service(MANAGE_GROUP + (id ? '/' + id : ''), "POST", postdata);
      if (response.status === 200) {
        let data: any = {
          type: 'create',
          value: response.data?.options,
        }
        if (id) {
          data = {
            ...response?.data,
            data: {
              ...response?.data?.data,
              title: (cloneId ? "Clone of " : "") + (response.data?.data?.title ?? "")
            }
          };
        }
        dispatch({ type: SET_OPTIONS, value: data });
      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e: any, field: string) => {
    const checkboxFields = ['user', 'admin', 'team'];
    const { target } = e;
    let value: any = target?.type === 'checkbox' ? target?.checked : target?.value;

    if (checkboxFields.includes(field)) {
      value = {
        value: target?.id,
        checked: value
      }
    }
    dispatch({ type: UPDATE_FIELD, field, value });
  };

  const validate = (field?: string) => {
    if (field) {
      dispatch({ type: SET_ERROR, field });
    } else {
      const allTabsErrors = validateAllTabs();
      if (Object.values(allTabsErrors)?.some((tabErrors) => tabErrors.hasError)) {
        for (const tab in allTabsErrors) {
          dispatch({
            type: SET_ERROR,
            tab,
            errors: allTabsErrors[tab].errors,
            hasError: allTabsErrors[tab].hasError,
          });
        }
      } else {
        handleSubmit();
      }
    }
  };

  const validateAllTabs = () => {
    const allTabsErrors: Record<string, { errors: any, hasError: boolean }> = {};
    state.tabs.forEach((tab: any) => {
      const tabid: keyof GroupStateTabs = tab.id;
      const tabFields = getFormFields({ ...state, currentTab: tab.id });
      const formdata: any = state[tabid]?.data;
      const errors: Record<string, string> = {};
      tabFields.forEach((fieldGroup: any) => {
        Object.values(fieldGroup).forEach((field: any) => {
          if (field.required) {
            errors[field.name] = FormValidation?.nameValidation(formdata[field?.name] ?? "");
          }
        });
      });
      allTabsErrors[tab.id] = {
        errors,
        hasError: Object.values(errors)?.some((value) => value?.length > 0),
      };
    });
    return allTabsErrors;
  };

  const handleSearch = (e: any) => {
    dispatch({ type: SEARCH_OPTIONS, value: e?.target?.value });
  };

  const handleSubmit = async () => {
    const postdata = {
      ...state?.description?.data,
      ...state?.admin?.data,
      ...state?.user?.data,
      ...state?.team?.data,
    };

    try {
      const response = await APICALL.service(CREATE_GROUP + (editId ? '/' + editId : ''), "POST", postdata);
      !cloneId && CustomNotify({ type: response.status === 200 ? "success" : "error", message: response.message });
      if (response.status === 200) {
        navigate('/groups/manage');
      }
      if (response?.status === 422 && response?.errors?.title) {
        let errors: any = {
          ...state['description']?.errors,
          title: response?.errors?.title?.[0] ?? 'The title already exists'
        }
        dispatch({
          type: SET_ERROR,
          tab: 'description',
          errors: errors,
          hasError: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const CurrentTabComponent = tabComponents[state.currentTab];

  return (
    <>
    <div className="search-bar py-3">
      <TitleAtom title={t("Create Group")} />
    </div>
      <div className="tabs-container createGroupTabs">
        {state.tabs.map((tab, index) => (
          <button
            key={tab.id}
            onClick={() => handleTabChange(state, dispatch, 'switch', index)}
            className={`tab-button ${state.currentTab === tab.id ? 'active' : ''} ${tab?.error ? ' border-danger' : ''} mx-0`}
          >
            {t(tab.title)}
          </button>
        ))}
      </div>
      <div className="tab-content">
        {formConfig && CurrentTabComponent && (
          <CurrentTabComponent
            state={state}
            dispatch={dispatch}
            handleChange={handleChange}
            formConfig={formConfig}
            removeError={validate}
            handleSearch={handleSearch}
          />
        )}
      </div>
      <ActionButtonGroup
        maindivcss="navigation-buttons"
        backbuttoncss="back_btn"
        savebuttoncss="create_button"
        backTitle={dynamicSubmitAndBackFunction(state, 'back') ? t('Back') : t('Previous')}
        saveAndNextTitle={dynamicSubmitAndBackFunction(state, 'save') ? t('Save') : t('Next')}
        handleBackClick={() => dynamicSubmitAndBackFunction(state, 'back') ? navigate('/groups/manage') : handleTabChange(state, dispatch, 'previous')}
        handleSaveAndNextClick={() => dynamicSubmitAndBackFunction(state, 'save') ? validate() : handleTabChange(state, dispatch, 'next')}
      />
    </>
  );
};

export default CreateGroupOrganism;
