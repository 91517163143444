import React, { useEffect } from 'react';
import { APICALL } from '../../../services/ApiServices';
import { createDocuments, manageDocuments } from '../../../routes/ApiEndpoints';
import './CreateDocumentOrganism.css';
import ActionButtonGroup from '../../molecules/ActionButtonGroup';
import CommonTab from './tabs/CommonTab';
import GeneralTab from './tabs/GeneralTab';
import { dynamicSubmitAndBackFunction, handleTabChange } from '../../../services/HandleTabServices';
import { SEARCH_OPTIONS, SET_ERROR, SET_OPTIONS, UPDATE_FIELD } from '../../../pages/groups/context/Actions';
import { useNavigate } from 'react-router-dom';
import CustomNotify from '../../atoms/CustomNotify';
import FormValidation from '../../../services/formvalidation/Validation';
import { t } from '../../../services/translation/TranslationUtils';
import { DocumentStateTabs, TabComponentProps } from '../../../pages/documents/context/Interfaces';
import { getFormFields } from '../../../pages/documents/context/State';
import { useDocumentDispatch, useDocumentState } from '../../../pages/documents/context/GroupContext';
import S3Services from '../../../utils/S3Bucket/S3Services';
import TitleAtom from '../../common/TitleAtom';
import { mimeTypeMapping } from '../../../utils/constant';

const tabComponents: {
  [key: string]: React.ComponentType<TabComponentProps>;
} = {
  general: GeneralTab,
  staff: CommonTab,
  team: CommonTab,
};

const CreateDocumentsOrganism: React.FC = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const editId = searchParams.get("edit");
  const navigate = useNavigate();
  const state = useDocumentState();
  const dispatch = useDocumentDispatch();
  const formConfig: any = getFormFields(state);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const postdata = {
      document_id: editId
    };
    try {
      const response = await APICALL.service(manageDocuments, "POST", postdata);
      if (response.status === 200) {
        let data = {
          type: 'create',
          value: response.data?.options,
        }
        if (editId) {
          data = response.data;
        }
        dispatch({ type: SET_OPTIONS, value: data });
      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleFileOperation = (file: File, field: any) => {
    if (file) {
      const validFileExtensions = field.accept.split(',').map((ext: string) => ext.trim());
      const validMimeTypes = validFileExtensions.map((ext: any) => mimeTypeMapping[ext]);
      if (!validMimeTypes?.includes(file.type)) {
        alert(`Invalid file type. Please upload a file of type: ${field.accept}`);
        return;
      }

      let maxSize = 10 * 1024 * 1024; //10MB
      if (file.size > maxSize) {
        alert(`File size exceeds the limit of ${maxSize / (1024 * 1024)}MB. Please upload a smaller file.`);
        return;
      }

      let value = {
        file: file,
        file_path: file && URL.createObjectURL(file),
        file_name: file && file?.name
      }
      dispatch({ type: UPDATE_FIELD, field: field?.name, value });
    }
  };

  const handleChange = (e: any, field: any) => {
    const checkboxFields = ['staff', 'team'];
    let value: any = null;
    if (field?.name === 'files') {
      handleFileOperation(e, field)
    } else {
      const { target } = e;
      value = target?.type === 'checkbox' ? target?.checked : target?.value;
      if (checkboxFields.includes(field?.name)) {
        value = {
          value: target?.id,
          checked: value
        }
      }
      dispatch({ type: UPDATE_FIELD, field: field?.name, value });
    }
  };

  const validate = (field?: string) => {
    if (field) {
      dispatch({ type: SET_ERROR, field });
    } else {
      const allTabsErrors = validateAllTabs();
      if (Object.values(allTabsErrors)?.some((tabErrors) => tabErrors.hasError)) {
        for (const tab in allTabsErrors) {
          dispatch({
            type: SET_ERROR,
            tab,
            errors: allTabsErrors[tab].errors,
            hasError: allTabsErrors[tab].hasError,
          });
        }
      } else {
        handleSubmit();
      }
    }
  };

  const validateAllTabs = () => {
    const allTabsErrors: Record<string, { errors: any, hasError: boolean }> = {};
    state.tabs.forEach((tab: any) => {
      const tabid: keyof DocumentStateTabs = tab.id;
      const tabFields = getFormFields({ ...state, currentTab: tab.id });
      const formdata: any = state[tabid]?.data;
      const errors: Record<string, string> = {};
      tabFields.forEach((fieldGroup: any) => {
        Object.values(fieldGroup).forEach((field: any) => {
          if (field.required) {
            let value = field?.name === 'files' ? formdata[field?.name]?.file_path ?? "" : formdata[field?.name] ?? "";
            errors[field.name] = FormValidation?.nameValidation(value ?? "");
          }
        });
      });
      allTabsErrors[tab.id] = {
        errors,
        hasError: Object.values(errors)?.some((value) => value?.length > 0),
      };
    });
    return allTabsErrors;
  };

  const handleSearch = (e: any) => {
    dispatch({ type: SEARCH_OPTIONS, value: e?.target?.value });
  };

  const handleSubmit = async () => {
    let accept = ['files'];
    const object: any = {};
    const uploadPromises: Promise<void>[] = [];
    try {
      const loadingIcon = document.getElementById("loading-div-id");
      if (loadingIcon) loadingIcon.style.display = "block";
      Object.entries(state?.general?.data).forEach(([key, value]) => {
        if (key && accept?.includes(key) && value !== null && typeof value === 'object' && 'file' in value && value?.file) {
          const uploadPromise = S3Services.uploadToS3([value?.file], 'documents/')
            .then((uploadedFiles) => {
              object[key] = uploadedFiles;
            });
          uploadPromises.push(uploadPromise);
        }
      });
      await Promise.all(uploadPromises);
      if (loadingIcon) loadingIcon.style.display = "none";
      const postdata = {
        general: {
          title: state?.general?.data?.title,
          description: state?.general?.data?.description,
          add_to_counter: state?.general?.data?.add_to_counter,
          send_document_notification: state?.general?.data?.send_document_notification,
          files: [{
            file_path: (object?.files && object?.files?.length) ? object?.files[0] : state?.general?.data?.files?.file_path,
            file_name: state?.general?.data?.files?.file_name
          }]
        },
        team: state?.team?.data?.team,
        staff: state?.staff?.data?.staff,
      };

      const response = await APICALL.service(createDocuments + (editId ? '/' + editId : ''), "POST", postdata);
      CustomNotify({ type: response.status === 200 ? "success" : "error", message: response.message });
      if (response.status === 200) {
        navigate('/documents/manage');
      }
    } catch (error) {
      console.log(error);
    }
  };
  const CurrentTabComponent = tabComponents[state.currentTab];

  return (
    <>
      <div className="search-bar py-3">
        <TitleAtom title={t("Create document")} />
      </div>
      <div className="tabs-container createGroupTabs">
        {state.tabs.map((tab, index) => (
          <button
            key={tab.id}
            onClick={() => handleTabChange(state, dispatch, 'switch', index)}
            className={`tab-button ${state.currentTab === tab.id ? 'active' : ''} ${tab?.error ? ' border-danger' : ''}`}
          >
            {t(tab.title)}
          </button>
        ))}
      </div>
      <div className="tab-content">
        {formConfig && CurrentTabComponent && (
          <CurrentTabComponent
            state={state}
            dispatch={dispatch}
            handleChange={handleChange}
            formConfig={formConfig}
            removeError={validate}
            handleSearch={handleSearch}
          />
        )}
      </div>
      <ActionButtonGroup
        maindivcss="navigation-buttons"
        backTitle={dynamicSubmitAndBackFunction(state, 'back') ? t('Back') : t('Previous')}
        saveAndNextTitle={dynamicSubmitAndBackFunction(state, 'save') ? t('Save') : t('Next')}
        handleBackClick={() => dynamicSubmitAndBackFunction(state, 'back') ? navigate('/documents/manage') : handleTabChange(state, dispatch, 'previous')}
        handleSaveAndNextClick={() => dynamicSubmitAndBackFunction(state, 'save') ? validate() : handleTabChange(state, dispatch, 'next')}
      />
    </>
  );
};

export default CreateDocumentsOrganism;
