import React, { useEffect } from 'react';
import { useNewsDispatch, useNewsState } from '../../../../pages/news/context/NewsContext';
import { APICALL } from '../../../../services/ApiServices';
import '../CreateNewsOrganism.css';
import ActionButtonGroup from '../../../molecules/ActionButtonGroup';
import { dynamicSubmitAndBackFunction, handleTabChange } from '../../../../services/HandleTabServices';
import { useNavigate } from 'react-router-dom';
import CustomNotify from '../../../atoms/CustomNotify';
import FormValidation from '../../../../services/formvalidation/Validation';
import { NewsStateTabs, TabComponentProps } from '../../../../pages/news/context/Interfaces';
import CommonTab from './tabs/CommonTab';
import { NewsOptonsPayload, getFormFields } from '../../../../pages/news/context/State';
import { SEARCH_OPTIONS, SET_ERROR, SET_OPTIONS, UPDATE_FIELD } from '../../../../pages/news/context/Actions';
import { CREATE_NEWS, MANAGE_NEWS, fetchOptions } from '../../../../routes/ApiEndpoints';
import DescriptionTab from './tabs/DescriptionTab';
import S3Services from '../../../../utils/S3Bucket/S3Services';
import { t } from '../../../../services/translation/TranslationUtils';
import TitleAtom from '../../../common/TitleAtom';

const tabComponents: {
  [key: string]: React.ComponentType<TabComponentProps>;
} = {
  description: DescriptionTab,
  user: CommonTab,
  team: CommonTab,
  admin: CommonTab,
  moderator: CommonTab
};

const CreateNewsOrganism: React.FC = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const editId = searchParams.get("edit");
  const navigate = useNavigate();
  const state = useNewsState();
  const dispatch = useNewsDispatch();
  const formConfig: any = getFormFields(state);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const postdata = editId ? {
      "posts": false
    } : NewsOptonsPayload;
    try {

      const response = await APICALL.service(editId ? (MANAGE_NEWS + '/' + editId) : fetchOptions, "POST", postdata);
      if (response.status === 200) {
        let data = {
          type: 'create',
          value: response.data,
        }
        if (editId) {
          data = response.data;
        }
        dispatch({ type: SET_OPTIONS, value: data });
      } else {
        console.log(response.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = async (e: any, field: string) => {
    const checkboxFields = ['user', 'admin', 'team', 'moderator'];
    let value: any = null;
    if (field === 'header' || field === 'thumbnails') {
      value = {
        file: e,
        file_path: e && URL.createObjectURL(e),
        file_name: e && e?.name
      }
    } else {
      if (field === 'language') {
        value = e?.value ?? null;
      } else {
        value = e?.target?.type === 'checkbox' ? e.target?.checked : (e?.target?.value);
        if (checkboxFields.includes(field)) {
          value = {
            value: e?.target?.id,
            checked: value
          }
        }
      }
    }
    dispatch({ type: UPDATE_FIELD, field, value });
  };

  const validate = (field?: string) => {
    if (field) {
      dispatch({ type: SET_ERROR, field });
    } else {
      const allTabsErrors = validateAllTabs();
      if (Object.values(allTabsErrors)?.some((value) => value?.hasError)) {
        for (const tab in allTabsErrors) {
          dispatch({
            type: SET_ERROR,
            tab,
            errors: allTabsErrors[tab].errors,
            hasError: allTabsErrors[tab].hasError,
          });
        }
      } else {
        handleSubmit();
      }
    }
  };

  const validateAllTabs = () => {
    const allTabsErrors: Record<string, { errors: any, hasError: boolean }> = {};
    state.tabs.forEach((tab: any) => {
      const tabid: keyof NewsStateTabs = tab.id;
      const tabFields = getFormFields({ ...state, currentTab: tab.id });
      const formdata: any = state[tabid]?.data;
      const errors: Record<string, string> = {};
      tabFields.forEach((fieldGroup: any) => {
        Object.values(fieldGroup).forEach((field: any) => {
          if (field?.required) {
            errors[field.name] = FormValidation?.nameValidation(formdata[field?.name] ?? "");
          }
        });
      });
      allTabsErrors[tab.id] = {
        errors,
        hasError: Object.values(errors)?.some((value) => value?.length > 0),
      };
    });
    return allTabsErrors;
  };

  const handleSearch = (e: any) => {
    dispatch({ type: SEARCH_OPTIONS, value: e?.target?.value });
  };

  const handleSubmit = async () => {
    let accept = ['header', 'thumbnails'];
    const object: any = {};
    const uploadPromises: Promise<void>[] = [];
    try {
      const loadingIcon = document.getElementById("loading-div-id");
      if (loadingIcon) loadingIcon.style.display = "block";
      Object.entries(state?.description?.data).forEach(([key, value]) => {
        if (key && accept?.includes(key) && value !== null && typeof value === 'object' && 'file' in value && value?.file) {
          const uploadPromise = S3Services.uploadToS3([value?.file], ('posts/' + key + '/'))
            .then((uploadedFiles) => {
              object[key] = uploadedFiles;
            });
          uploadPromises.push(uploadPromise);
        }
      });
      await Promise.all(uploadPromises);
      if (loadingIcon) loadingIcon.style.display = "none";
      // Append other data
      const data = {
        description: {
          ...state?.description?.data,
          header: {
            file_path: (object?.header && object?.header?.length) ? object?.header[0] : state?.description?.data?.header?.file_path,
            file_name: state?.description?.data?.header?.file_name
          },
          thumbnails: {
            file_path: (object?.thumbnails && object?.thumbnails?.length) ? object?.thumbnails[0] : state?.description?.data?.thumbnails?.file_path,
            file_name: state?.description?.data?.thumbnails?.file_name
          }
        },
        ...state?.admin?.data,
        ...state?.user?.data,
        ...state?.moderator?.data,
        ...state?.team?.data,
      };

      const response = await APICALL.service(CREATE_NEWS + (editId ? ('/' + editId) : ''), 'POST', data);

      CustomNotify({ type: response.status === 200 ? "success" : "error", message: response.message });
      if (response.status === 200) {
        navigate('/news/manage')
      }
      if (response?.status === 422 && response?.errors?.['description.title']) {
        let errors: any = {
          ...state['description']?.errors,
          title: response?.errors?.['description.title']?.[0] ?? 'The title already exists'
        }
        dispatch({
          type: SET_ERROR,
          tab: 'description',
          errors: errors,
          hasError: true,
        });
      }
    } catch (error) {
      CustomNotify({ type: "error", message: "Error while creating news" });
    }
  };

  const CurrentTabComponent = tabComponents[state.currentTab];

  return (
    <>
      <div className="search-bar py-3">
        <TitleAtom title={t("Create News")} />
        <div className="tabs-container createGroupTabs pt-2 mb-0">
          {state.tabs.map((tab, index) => (
            <button
              key={tab.id}
              onClick={() => handleTabChange(state, dispatch, 'switch', index)}
              className={`tab-button ${state.currentTab === tab.id ? 'active' : ''} ${tab?.error ? ' border-danger' : ''}`}
            >
              {tab.title}
            </button>
          ))}
        </div>
      </div>
      <div className="tab-content">
        {CurrentTabComponent && (
          <CurrentTabComponent
            state={state}
            dispatch={dispatch}
            handleChange={handleChange}
            formConfig={formConfig}
            removeError={validate}
            handleSearch={handleSearch}
          />
        )}
      </div>
      <ActionButtonGroup
        maindivcss="navigation-buttons my-0 py-3"
        backTitle={dynamicSubmitAndBackFunction(state, 'back') ? t('Back') : t('Previous')}
        saveAndNextTitle={dynamicSubmitAndBackFunction(state, 'save') ? t('Save') : t('Next')}
        handleBackClick={() => dynamicSubmitAndBackFunction(state, 'back') ? navigate('/news/manage') : handleTabChange(state, dispatch, 'previous')}
        handleSaveAndNextClick={() => dynamicSubmitAndBackFunction(state, 'save') ? validate() : handleTabChange(state, dispatch, 'next')}
      />
    </>
  );
};

export default CreateNewsOrganism;
