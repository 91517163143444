export const tableHeaders = [
    { name: 'Title', width: 50 },
    { name: 'Type', width: 20 },
    { name: 'Number of members', width: 10 },
    { name: 'Activity', width: 10 }
];

export const tableValues = [
    'title',
    'group_type',
    'user_counts',
    'activity'
];

export const manageState = {
    filters: {
        groups: [],
        status: []
    },
    archivepopup: {
        id: null,
        status: null,
        showpopup: false
    },
    options: {} as any,
    data: [] as any,
    manage: true,
    initialCall: true,
    current_page: 1,
    limit: 10,
    status: null,
    totalPages: 0,
    offset: 0,
    totalCount: 0,
    refresh: true
};

export const filterValues = (options: any) => {    
    let row = [
        {
            name: 'groups',
            type: 'multi-select-without-label',
            placeholder: 'Select group',
            value: [],
            options: options?.Group,
        },
        {
            name: 'status',
            type: 'multi-select-without-label',
            placeholder: 'Select status',
            value: [],
            options: [
                { value: true, label: "Active" },
                { value: false, label: "Inactive" },
            ],
        },
    ]
    return row;
}
