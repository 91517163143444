import React, { useEffect, useState } from "react";
import { renderInput } from "../../../services/form/FormElements";
import ViewPreviewFile from "../../molecules/ViewPreviewFile";
import { CreateAlertFormProps } from "./Types";
import { filterValues } from "./State";

const CreateAlertForm: React.FC<CreateAlertFormProps> = ({
  removeError,
  handleChange,
  data,
  errors,
  options
}) => {
  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    let row = filterValues(options);
    setRows(row);
  }, [options]);

  return (
    <div>
      <div className="row gap-3">
        {rows && rows.map((fields: any, index: number) => (
          <div key={index}>
            {Object.values(fields)?.map((field: any) => (
              <div key={field.name}>
                {renderInput(
                  removeError,
                  field,
                  (e: any) => handleChange(e, field),
                  data,
                  errors,
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CreateAlertForm;
