import React from 'react';
import Select, { StylesConfig, ActionMeta, CSSObjectWithLabel, MenuPlacement, Theme } from 'react-select';
import { t } from '../../services/translation/TranslationUtils';

interface CustomOptionProps extends CSSObjectWithLabel {
  backgroundColor?: string;
  color?: string;
}

export type OptionValue = {
  value: string | number;
  label: string;
  color_code?: string;
};

export type SelectOption = OptionValue & {
  children?: SelectOption[];
};

export type SelectValue = SelectOption | SelectOption[] | null;

export interface Props<T> {
  handleChange?: (newValue: T, actionMeta: ActionMeta<unknown>) => void;
  handleClick?: () => void;
  standards?: T | T[] | null;
  options?: SelectOption[];
  disabled?: boolean;
  isMulti?: boolean;
  name?: string;
  placeholder?: string;
  className?: string;
  customStyle?: React.CSSProperties;
  classNamePrefix?: string;
  isSearchable?: boolean;
  isClearable?: boolean;
  menuPlacement?: MenuPlacement;
  styles?: StylesConfig;
  value?: T | null;
  inputRef?: any;
}

const MultiSelect = <T extends SelectValue>({
  handleChange,
  handleClick,
  standards,
  options,
  disabled,
  isMulti = false,
  name = '',
  placeholder = 'Select',
  className = '',
  customStyle = {},
  classNamePrefix = '',
  isSearchable = true,
  isClearable = true,
  menuPlacement = 'auto',
  styles = {},
  inputRef
}: Props<T>) => {

  const handleSelectChange = (newValue: unknown, actionMeta: ActionMeta<unknown>) => {
    handleChange && handleChange(newValue as T, actionMeta);
  };

  const themeModifier = (theme: Theme): Theme => ({
    ...theme,
    borderRadius: 3,
  });

  const handleSelectClick = () => {
    if (handleClick) {
      handleClick();
    }
  };

  const customStyles: StylesConfig = {
    option: (provided, state) => ({
      ...provided,

      backgroundColor: state.isSelected ? '#00a5ce' : provided.backgroundColor,
      '&:hover': {
        backgroundColor: '#e8f8fb',
      },
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: 'none', // Hides the separator
    }),

  };

  return (
    <div style={customStyle} onClick={handleSelectClick}>
      <Select
        ref={inputRef}
        styles={{ ...customStyles, ...styles }}
        placeholder={placeholder}
        value={standards}
        onChange={handleSelectChange}
        isDisabled={disabled}
        options={options}
        menuPlacement={menuPlacement}
        isSearchable={isSearchable}
        isMulti={isMulti}
        name={name}
        className={className}
        classNamePrefix={classNamePrefix || 'cursor-pointer react-select-prefix'}
        maxMenuHeight={300}
        noOptionsMessage={() => t('No option')}
        isClearable={isClearable}
        theme={themeModifier}
      />
    </div>
  );
};

export default MultiSelect;
