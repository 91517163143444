import React from "react";

interface TitleAtomProps {
  title?: string;
}

const TitleAtom: React.FC<TitleAtomProps> = ({ title }) => {
  return (
    <div className="col-md-6">
      <h1 className="page-title mb-0">
        {title}
      </h1>
    </div>
  );
};
export default TitleAtom;
