import React, { ChangeEvent } from 'react';
import Button from '../../../atoms/Button';
import { t } from '../../../../services/translation/TranslationUtils';
import InputTextfield from '../../../atoms/InputTextField';
import { ManageNewsFiltersProps } from '../../../../pages/news/context/Interfaces';
import ResetBtn from '../../../common/ResetBtn';

const ManageNewsFilters: React.FC<ManageNewsFiltersProps> = ({
  value,
  handleSearch,
  handleFilter,
  handleClear
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const search = e.target.value;
    handleFilter(search);
  }

  return (
    <div className='d-flex'>
      <div className='flex-1 me-3'>
        <InputTextfield
          placeholder={t("Search by title")}
          className='w-100 form-control'
          value={value}
          handleChange={handleChange}
        />
      </div>
      <div className=''>
        <div className="d-flex align-items-center justify-content-end">
          <Button
            className='search_btn me-3'
            title={t('Search')}
            handleClick={handleSearch}
          />
          <ResetBtn handleResetClick={handleClear} />
        </div>
      </div>
    </div>
  )
}

export default ManageNewsFilters;
