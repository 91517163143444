import ImageField from "../../../atoms/ImageField";
import FileUploadComponent from "../../../molecules/FileUploadMolecule";
import { fileProfileField } from "../State";
import { ProfilePictureProps } from "../Types";
import styles from '../Users.module.css';

const ProfilePicture: React.FC<ProfilePictureProps> = ({
    file_path,
    handleFileUpload,
}) => {
    const getProfilePath = (file_path: string) => {
        if (
            file_path
            && file_path !== ''
            && file_path !== null
            && file_path !== undefined
        ) {
            return file_path
        } else {
            return "../static/images/profile.png";
        }
    }

    return (
        <div className="position-relative d-inline-block">
            <ImageField
                className={`${styles.profile_image} rounded-circle`}
                value={getProfilePath(file_path)}
                altText={'profile'}
            />
            <div className="position-absolute cameraIconPosition">
                <FileUploadComponent
                    field={fileProfileField}
                    handleChange={handleFileUpload}
                    value={file_path}
                />
            </div>
        </div>
    );
};

export default ProfilePicture;
