import React, { ChangeEvent, useEffect, useState } from "react";
import CreateUserForm from "./CreateUserForm";
import { APICALL } from "../../../../services/ApiServices";
import { createUser, fetchOptions, manageUser } from "../../../../routes/ApiEndpoints";
import { initialstate, options } from "../State";
import CustomNotify from "../../../atoms/CustomNotify";
import { useNavigate } from "react-router-dom";
import { Files } from "../../../common/CommonInterfaces";
import S3Services from "../../../../utils/S3Bucket/S3Services";

const CreateUserOrganism: React.FC = () => {
    const [state, setState] = useState(initialstate);
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const edit = urlParams.get("id");
    const clone = urlParams.get("clone");

    useEffect(() => {
        if (edit || clone) {
            fetchData();
        } else {
            fetchOption();
        }
    }, [edit, clone]);

    const fetchData = async () => {
        try {
            let postdata = {
                options: options
            };
            let id = edit ?? clone;
            const response = await APICALL.service(manageUser + (id ? ('/' + id) : ""), 'POST', postdata);

            if (response?.status === 200) {
                updateState(response);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const fetchOption = async () => {
        try {
            let postdata = {
                data: options
            };

            const response = await APICALL.service(fetchOptions, 'POST', postdata);

            if (response?.status === 200) {
                setState((prevState: any) => ({
                    ...prevState,
                    options: response?.data
                }));
            }
        } catch (error) {
            console.log(error);
        }
    }

    const updateState = (response: any) => {
        let data = response?.data;
        setState((prevState: any) => ({
            ...prevState,
            form: {
                ...prevState.form,
                first_name: data?.profile?.first_name ?? '',
                last_name: data?.profile?.last_name ?? '',
                gender: data?.profile?.gender_id,
                date_of_birth: data?.profile?.date_of_birth,
                office: data?.profile?.office,
                phone: data?.phone,
                reference: data?.profile?.reference,
                employed_from: data?.profile?.employed_from ?? '',
                employed_till: data?.profile?.employed_till ?? '',
                company: data?.company?.id,
                role: data?.roles?.[0]?.id,
                title: data?.profile?.title,
                username: data?.username,
                language: data?.languages?.[0]?.id,
                business_unit: data?.business_unit?.[0]?.id,
                sub_groups: data?.profile?.sub_groups,
                contract_type: data?.profile?.contract_type_id,
                email: data?.email,
                active: data?.status ?? true,
                user_id: edit ?? null,
                profile_picture: {
                    file: null,
                    file_path: data?.profile?.profile_picture?.file_path,
                    file_name: data?.profile?.profile_picture?.file_name
                }
            },
            options: response?.options
        }));
    }

    const removeError = (fieldName: any) => {
        if (initialstate.errors.hasOwnProperty(fieldName)) {
            setState((prevState: any) => ({
                ...prevState,
                errors: {
                    ...prevState.errors,
                    [fieldName]: ""
                }
            }));
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: string) => {
        if (e != null) {
            const { target } = e;

            if (target) {
                let value: any;
    
                if (target instanceof HTMLInputElement && target.type === 'checkbox') {
                    value = target.checked;
                } else {
                    value = target.value;
                }
                setState((prevState: any) => ({
                    ...prevState,
                    form: {
                        ...prevState.form,
                        [field]: value,
                    }
                }));
            } else {
                setState((prevState: any) => ({
                    ...prevState,
                    form: {
                        ...prevState.form,
                        [field]: (e as any).value ?? e,
                    }
                }));
            }
        } else {
            field &&
                setState((prevState: any) => ({
                    ...prevState,
                    form: {
                        ...prevState.form,
                        [field]: null,
                    }
                }));
        }
    }

    const handleDateFormat = (dateValue: any, field: string) => { // onchange of the date
        if (dateValue !== null) { // formatting and setting the state with the formatted date
            const date = new Date(dateValue);
            const timezoneOffset = date.getTimezoneOffset() * 60 * 1000; // To set time zone offset
            const formattedDate = new Date(date.getTime() - timezoneOffset)
                .toISOString()
                .slice(0, 10);
            setState((prevState: any) => ({
                ...prevState,
                form: {
                    ...prevState.form,
                    [field]: formattedDate
                }
            }));
        } else { // setting the state with the null when it is cleared
            setState((prevState: any) => ({
                ...prevState,
                form: {
                    ...prevState.form,
                    [field]: null
                }
            }));
        }
    };

    const getRequestData = (filePath: string) => {
        return {
            ...state.form,
            status: state.form.active,
            gender_id: state.form.gender,
            roles: state.form.role,
            contract_type_id: state.form.contract_type,
            profile_picture: filePath
        }
    }

    const handleSubmit = async (errors: any, valid: boolean) => {
        setState((prevState: any) => ({
            ...prevState,
            saving: true
        }));
        if (valid) {
            try {
                let filePath = '';
                const uploadPromises: Promise<void>[] = [];
                let profile_picture = state?.form?.profile_picture;
                const loadingIcon = document.getElementById("loading-div-id");
                if (loadingIcon) loadingIcon.style.display = "block";
                console.log(profile_picture.file);
                if ('file' in profile_picture && profile_picture.file) {
                    const uploadPromise = S3Services.uploadToS3([profile_picture.file], 'profile/')
                        .then((uploadedFiles) => {
                            filePath = uploadedFiles[0];
                        });
                    uploadPromises.push(uploadPromise);
                }
                await Promise.all(uploadPromises);
                if (loadingIcon) loadingIcon.style.display = "none";

                let postData = getRequestData(filePath);

                const response = await APICALL.service(createUser, 'POST', postData);

                if (response?.status === 200) {
                    navigate(`/employee/manage`);
                    CustomNotify({
                        type: 'success',
                        message: response?.message
                    });
                } else {
                    setState((prevState: any) => ({
                        ...prevState,
                        saving: false
                    }));
                    CustomNotify({
                        type: 'error',
                        message: response?.message
                    });
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            setState((prevState: any) => ({
                ...prevState,
                saving: false,
                errors: errors
            }));
        }
    }

    const handleProfileUpload = (file: File, field: any) => {
        const newFile: Files = {
            file,
            file_path: file && URL.createObjectURL(file),
            file_name: file?.name,
        };

        setState((prevState: any) => ({
            ...prevState,
            form: {
                ...prevState.form,
                [field.name]: newFile
            }
        }));
    };

    return (
        <CreateUserForm
            removeError={removeError}
            handleChange={handleChange}
            handleDateFormat={handleDateFormat}
            options={state.options}
            data={state?.form}
            errors={state.errors}
            handleSubmit={handleSubmit}
            handleFileUpload={handleProfileUpload}
            saving={state.saving}
        />
    );
};

export default CreateUserOrganism;
