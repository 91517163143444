import React, { useEffect, useState } from 'react';
import { t } from "../../../../services/translation/TranslationUtils";
import Button from "../../../atoms/Button";
import { profileValues } from '../State';
import { renderInput } from '../../../../services/form/FormElements';
import { CreateUserFormProps } from '../Types';
import { inputValidation } from '../../../../services/formvalidation/ValidationTypes';
import { useNavigate } from 'react-router-dom';
import ProfilePicture from './ProfilePicture';
import TitleAtom from '../../../common/TitleAtom';

const MyProfileForm: React.FC<CreateUserFormProps> = ({
  removeError,
  handleChange,
  options,
  data,
  errors,
  handleSubmit,
  handleFileUpload
}) => {
  const [rows, setRows] = useState<any[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    let row = profileValues(options);
    setRows(row);
  }, [options]);

  const validate = () => {
    const form = rows.flatMap(row => Object.values(row));
    const error: any = {};

    form.forEach((element: any) => {
      if (errors.hasOwnProperty(element.name)) {
        error[element.name] = inputValidation(data[element.name], element.validation)
      }
    })
    const valid = Object.values(error)?.every(message => message === '');
    handleSubmit(error, valid);
  }

  return (
    <>
      <div className="search-bar py-3">
        <TitleAtom title={t("My profile")} />
      </div>
      <div className="form p-5">
        <div>
          <Button
            className='create_button float-end'
            title={t('Reset password')}
            handleClick={() => navigate('/employee/password')}
          />
        </div>
        <div className="text-center">
          <ProfilePicture
            file_path={data?.profile_picture?.file_path}
            handleFileUpload={handleFileUpload}
          />
        </div>
        {rows.map((values, rowIndex) => (
          <div className="d-flex flex-row gap-3 my-3" key={rowIndex}>
            {Object.values(values).map((value: any) => (
              <div className={`flex-1 w-25`} key={value?.name}>
                {
                  renderInput(
                    removeError,
                    value,
                    handleChange,
                    data,
                    errors
                  )
                }
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-between mt-4">
        <Button
          className="back_btn "
          handleClick={() => navigate('/')}
          title={t('Back')}
        />
        <Button
          className="create_button"
          handleClick={validate}
          title={t('Save')}
        />
      </div>
    </>
  );
};

export default MyProfileForm;
