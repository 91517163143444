import React, { useEffect, useState } from "react";
import { formValues } from "../State";
import { renderInput } from "../../../../services/form/FormElements";
import { CreateUserFormProps } from "../Types";
import Button from "../../../atoms/Button";
import { t } from "../../../../services/translation/TranslationUtils";
import { inputValidation } from "../../../../services/formvalidation/ValidationTypes";
import { useNavigate } from "react-router-dom";
import ToggleSwitch from "../../../atoms/ToggleSwitch";
import ProfilePicture from "../profile/ProfilePicture";
import TitleAtom from "../../../common/TitleAtom";

const CreateUserForm: React.FC<CreateUserFormProps> = ({
  removeError,
  handleChange,
  handleDateFormat,
  options,
  data,
  errors,
  handleSubmit,
  handleFileUpload,
  saving = false
}) => {
  const [rows, setRows] = useState<any[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    let row = formValues(options);
    setRows(row);
  }, [options]);

  const validate = () => {
    if (!saving) {
      const form = rows.flatMap(row => Object.values(row));
      const error: any = {};

      form.forEach((element: any) => {
        if (errors.hasOwnProperty(element.name)) {
          error[element.name] = inputValidation(data[element.name], element.validation)
        }
      })
      const valid = Object.values(error)?.every(message => message === '');
      handleSubmit(error, valid);
    }
  }

  return (
    <>
      <div className="search-bar">
        <div className="row py-3">
          <TitleAtom title={t("Create employee")} />
        </div>
      </div>
      <div className="form p-5 overflow-auto" style={{maxHeight:"calc(100vh - 230px)"}}>
        <div className="d-flex justify-content-between">
          <ProfilePicture
            file_path={data?.profile_picture?.file_path}
            handleFileUpload={handleFileUpload}
          />
          <div className="d-flex align-items-center">
            <ToggleSwitch
              id={'active'}
              key={'active'}
              name={'active'}
              title={t('Active')}
              checked={data.active}
              onChange={(e) => handleChange(e, 'active')}
            />
          </div>
        </div>
        {rows.map((values, rowIndex) => (
          <div className="d-flex flex-row gap-3 my-3" key={rowIndex}>
            {Object.values(values).map((value: any) => (
              <div className={`flex-1 w-25`} key={value?.name}>
                {
                  renderInput(
                    removeError,
                    value,
                    value?.type == 'date' ? handleDateFormat : handleChange,
                    data,
                    errors
                  )
                }
              </div>
            ))}
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-between py-4">
        <Button
          className="back_btn"
          handleClick={() => { navigate('/employee/manage') }}
          title={t('Back')}
        />
        <Button
          className="create_button"
          handleClick={validate}
          title={saving ? t('Saving...') : t('Save')}
        />
      </div>

    </>
  );
};

export default CreateUserForm;
